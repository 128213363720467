@import url("./table.css");
@import url("./chips.css");
@import url("./calendar.css");

.no-shadow {
  box-shadow: none !important;
}
.no-borders {
  border: none !important;
}

.MuiPickersLayout-actionBar {
  padding: 16px !important;
}

.MuiPickersLayout-actionBar .MuiButtonBase-root {
  padding: 4px 16px;
}

.MuiAutocomplete-endAdornment button {
  box-shadow: none;
  margin-left: 8px;
}

.bg-white {
  background: white;
}

.mui-dark .bg-white {
  background: #27252d;
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background-color: transparent; /* Container background color */
}

body::-webkit-scrollbar-thumb {
  background-color: #00fd7a; /* Scrollbar thumb color */
}

/* For Firefox */
body {
  scrollbar-width: thin; /* Sets scrollbar width (thin, auto, none) */
  scrollbar-color: #00fd7a transparent; /* thumb color and track color */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: dark;
}

.mui-dark input:-webkit-autofill {
  -webkit-text-fill-color: white;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.clamp-two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

pre {
  white-space: pre-wrap; /* Preserve whitespace but allow wrapping */
  word-wrap: break-word; /* Legacy for overflow-wrap */
  overflow-wrap: break-word; /* Break long words if needed */
  max-width: 100%; /* Optional: Contain within parent */

  /* Optional: Add hyphens for word breaks */
  hyphens: auto; /* Hyphenate words when breaking */
  font-size: 14px;
}
